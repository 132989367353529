@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body {
  background-color: #e6e6e6;
} 
* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
input[type=email], input[type=name],select {
  padding: 7.5px 15px;
  
  border: 1px solid #c9c9c9;
}
.links-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 10px;
  margin-top: 20px;
}
input[type=email]:focus, input[type=name]:focus,select:focus {
  border: 1px solid #e40000;
  box-shadow: 0px 0px 10px 0px rgb(162, 0, 0);
}
*:focus, *:focus-visible {
  outline: 2px solid #e40000;
}
p {
  color: #596b76;
}
.pay-now {
  padding: 10px 20px;
  background-color: #1c53de;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: 0;
  
  cursor: pointer;
  transition: 0.1s;
}
.pay-now:hover {
  background-color: #276fff;
}
.pay-now:active {
  background-color: #10449d;
}
.pay-now:focus {
  outline-color: #10449d;
}
button {
  padding: 10px 20px;
  background-color: #e40000;
  color: white;
  border: 0;
  
  cursor: pointer;
  transition: 0.1s;
}
button:hover {
  background-color: #ff2727;
}
button:active {
  background-color: #cc1818;
  box-shadow: 0px 0px 10px 0px rgb(162, 0, 0);
}
h1,h2, h3, h4 {
  margin: 0;
}
.loading-div {
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
h1 {
  margin-bottom: 25px;
}
p {
  text-align: left;
}
h2 {
  text-align: left;
}
.App {
  display: flex;
  flex-direction: column;
}
.main {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  padding: 100px;
}
.main-main {
  display: flex;
  align-self: center;
  flex-direction: row;
  text-align: center;
  gap: 20px;
}
.footer {
  display: flex;
  flex-direction: row;
  min-width: 800px;
  width: calc(100% - 46px);
  margin-top: 20px;
  padding: 20px;
  max-width: 1200px;
  justify-content: space-between;
  margin: 0px 100px;
  align-self: center;
  justify-self: center;
  border: 5px ridge #d1d1d1;
  border-style: outset;
  background-color: #ffffff;
}
.payment-form {
  width: 100%;
}
.logo {
  width: 100%;
  align-self: center;
}
.logo-div {
  margin-top: 50px;
  padding: 20px;
  width: 10%;
  border: 5px ridge #d1d1d1;
  border-style: outset;
  background-color: #ffffff;
  align-self: center;
}
.stock-div {
  display: flex;
  flex-direction: row;
  /* margin-top: 20px; */
  color: #888888;
  gap: 20px;
}
.stock-p {
  width: fit-content;
  margin: 0;
}
round[color=green] {
  align-self: center;
  background-color: rgb(48, 163, 28);
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
round[color=red] {
  align-self: center;
  background-color: rgb(163, 28, 28);
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
.main-main-main {
  background-color: #ffffff;
  width: 58.5%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 5px ridge #d1d1d1;
  border-style: outset;
  align-items: flex-start;
}
select {
  max-width: 100%;
  min-width: 282px;
}

.input-inside {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
label {
  font-weight: bold;
}
.input-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
  margin-top: 20px;
  align-items: flex-start;
}
.duration {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
button {
  padding: 10px 20px;
  font-size: 18px;
  height: fit-content;
  cursor: pointer;
}
.inputs {
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.faq {
  width: 38.5%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 5px ridge #d1d1d1;
  border-style: outset;
  background-color: #ffffff;
  align-items: flex-start;
}
.logo {
  border: none;
}
@media (max-width:768px) {
  .logo {
    width: 100%;
  }
  .logo-div {
    width: 20%;
  }
  .main {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    padding-top: 50px;
  }
  .main-main {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
  }
  .main-main-main, .faq {
    width: 85%;
  }
  .footer {
    min-width: unset;
    width: 85%;
    flex-direction: column;
    gap: 15px;
  }
  .input-div {
    flex-direction: column;
  }
}
